<template>
<div>
    <main id="main__wrapper" class="main__wrapper">
      <Loader :fullscreen=true v-if="isLoading" />
      <app-header class="outer__header" :darkHeader="darkHeader"></app-header>
      <router-view class="main__content" />
      <app-footer data-aos="fade-up" v-bind:data-aos-delay="150" data-aos-once="true" data-aos-anchor="body"></app-footer>
      <tooltip v-if="!displayMobileRange" />
    </main>
</div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Loader from '@/components/util/Loader'
import Header from '@/components/navigation/Header'
import Footer from '@/components/navigation/Footer.vue'
  export default {
    data() {
      return {
        darkHeader: false,
        page: []
      }
    },
    components:{
      Loader,
      'app-header' : Header,
      'app-footer' : Footer
    },
    computed: {
      ...mapState(['locale', 'isLoading', 'pressAuth']),
      displayMobileRange() {
          return this.$mq === 'sm' ? true : false
      },
    },
    watch: {
      locale() {
        this.$router.replace({ params: { lang: this.locale } }).catch(() => {})
      }
    },
    created() {
      this.$store.dispatch('changeLocale', this.$store.state.locale)
      axios.interceptors.request.use((config) => {
        this.$store.commit('SET_LOADING', true);
        return config;
      }, (error) => {
        this.$store.commit('SET_LOADING', false);
        return Promise.reject(error);
      });

      axios.interceptors.response.use((response) => {
        setTimeout(() => {
          setTimeout(() => {
            this.$store.commit('SET_LOADING', false);
          }, 1000)
        }, 500)
        
        return response;
      }, (error) => {
        this.$store.commit('SET_LOADING', false);
        return Promise.reject(error);
      });
    },
    methods: {
    }
  }
</script>

<style lang="scss">
.main__content {
  position: relative;
  height: 100%;
  min-height: calc(100vh - 90px);
  width: 100%;
  padding: 90px 0;
}
</style>
