import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n, { languages } from './i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  hash: false,
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}`,
      beforeEnter(to, from, next) {
        next(store.state.locale)
      }
    },
    {
      path: '/:lang',
      component: {
        render (c){ return c('router-view')},
      },
      beforeEnter(to, from, next) {
        let lang = to.params.lang
        if (languages.includes(lang)) {
          if (store.state.locale !== lang) {
            store.dispatch('changeLocale', lang)
          }
          return next()
        }
        return next({ path: store.state.locale })
      },
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
          props: {
            lang: store.state.locale,
          }
        },
        {
          path: 'paintings',
          name: 'paintings',
          props: {typeOfMedia: 'paintings', breadcrumbMenu: true},
          component: () => import(/* webpackChunkName: "paintings" */ './components/layouts/paintings/Paintings.vue')
        },
        {
          path: 'paintings/:slug',
          name: 'painting-category',
          component: () => import(/* webpackChunkName: "films-category" */ './components/layouts/paintings/PaintingsCategory.vue'),
          props: {
            lang: store.state.locale,
          }
          },
          {
          path: 'paintings/:slug/:id',
          name: 'painting',
          component: () => import(/* webpackChunkName: "film" */ './components/layouts/paintings/Painting.vue')
          },
        {
          path: 'films',
          name: 'films',
          component: () => import(/* webpackChunkName: "films" */ './components/layouts/films/Films.vue'),
          props: {
            lang: store.state.locale,
          }
        },
        {
        path: 'films/:slug',
        name: 'films-category',
        component: () => import(/* webpackChunkName: "films-category" */ './components/layouts/films/FilmsCategory.vue'),
        props: {
          lang: store.state.locale,
        }
        },
        {
        path: 'films/:slug/:id',
        name: 'film',
        component: () => import(/* webpackChunkName: "film" */ './components/layouts/films/Film.vue')
        },
        {
          path: 'press-images',
          name: 'press-images',
          props: {typeOfMedia: 'pressImages', breadcrumbMenu: false},
          component: () => import(/* webpackChunkName: "press-images" */ './components/layouts/MediaLayout.vue'),
          /*meta: {
            requiresPressAuth: true
          }*/
        },
        {
          path: 'press-images-verify',
          name: 'press-images-verify',
          props: {mediaAuth: true},
          component: () => import(/* webpackChunkName: "press-images-verify" */ './components/util/Form.vue'),
        },
        {
          path: 'the-artist',
          name: 'the-artist',
          props: {layoutType: 'artist'},
          component: () => import(/* webpackChunkName: "the-artist" */ './components/layouts/StandardLayout.vue')
        },
        {
          path: 'private-sales',
          name: 'private-sales',
          props: {layoutType: 'sales'},
          component: () => import(/* webpackChunkName: "private-sales" */ './components/layouts/StandardLayout.vue')
        },
        {
          path: 'privacy-and-cookies',
          name: 'privacy-and-cookies',
          props: {layoutType: 'standard'},
          component: () => import(/* webpackChunkName: "privacy" */ './components/layouts/StandardLayout.vue')
        },
        {
          path: 'contact',
          name: 'contact',
          props: true,
          component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
        },
        {
          path: 'credits',
          name: 'credits',
          props: {layoutType: 'standard'},
          component: () => import(/* webpackChunkName: "privacy" */ './components/layouts/StandardLayout.vue')
        },
        { 
          path: '*', 
          name: 'NotFound',
          component: () => import(/* webpackChunkName: "404" */ './components/util/404.vue')
        }
      ]
    }
  ],
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresPressAuth)) {
    if(!store.state.pressAuth) {
      next({
        name: 'press-images-verify'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;