<template>
  <div class="locale__switcher">
    <button
      href="#"
      class="locale__button btn"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="startArrowKeys"
      @keydown.down.exact.prevent="startArrowKeys"
    >
      <GlobeSVG class="locale__icon" />
      <span class="locale__long ml-2">{{ $t('fullLocale') }}</span>
      <svg class="locale__chevron" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="heroicon-ui" d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"></path></svg>
    </button>
    <transition name="dropdown-fade">
      <ul v-on-clickaway="hideDropdown" v-if="isVisible" ref="dropdown" class="locale__menu">
        <li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.short">
          <a
            href="#"
            @click.prevent="setLocale(`${lang.short}`)"
            ref="account"
            class=""
            @keydown.up.exact.prevent=""
            @keydown.tab.exact="focusNext(false)"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.esc.exact="hideDropdown"
          >
            <span class="ml-2">{{lang.long}}</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.locale__switcher {
  right: 0;
  .locale__button {
    margin: 0 0 0 auto;
    padding: 16px 0 16px 0 !important;
    min-width: auto !important;
    background: transparent;
    color: $white;
    padding: 0;
    font-size: 14px;
    border: none;
    outline: none;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .locale__icon {
    height: 24px;
    width: 24px;
    fill: $white;
    margin-right: 8px;
  }

  .locale__chevron {
    height: 28px;
    width: 28px;
  }

  .locale__menu {
    position: absolute;
    background: $black;
    padding: 30px 30px;
    min-width: 200px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.1), 0 1px 2px 0 rgba(255, 255, 255, 0.06);
    li {
      margin-bottom: 25px;
      a {
        span {
          text-transform: uppercase;
          font-size: 14px;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

  @media only screen and (min-width: 768px) {
    .locale__switcher {
      position: relative;
    }
    .locale__menu {
      top: 75px;
      right: 0px;
    }
  }

  @media only screen and (max-width: 767px) {
    .locale__switcher {
      position: relative;
    }
    .locale__menu {
      min-width: auto !important;
      top: 80px;
      right: -15px;
    }
  }
</style>

<script>
import { languages } from '@/i18n'
import { mixin as clickaway } from 'vue-clickaway'
import GlobeSVG from '@/components/util/Svg/GlobeSVG'
export default {
    name: 'LocaleSwitch',
    mixins: [ clickaway ],
    components: {
      GlobeSVG
    },
    data() {
      return {
        languageArray: languages,
        isVisible: false,
        focusedIndex: 0,
      }
    },
    computed: {
      langs() {
        return this.languageArray.reduce((filtered, lang) => {
          let fullName = ''
          if (lang) {
            switch (lang) {
              case 'en':
                fullName = 'English';
                break;
              case 'pt-BR':
                fullName = 'Português';
                break;
              case 'zh-Hant':
                fullName = '繁體中文';
                break;
              case 'zh-Hans':
                fullName = '简体中文';
                break;
              default:
                break;
            }
            filtered.push({short: lang, long: fullName})
          }
          return filtered;
        }, [])  
      },
      lang: {
        get: function() {
          return this.$store.state.locale
        },
        set: function(newVal) {
          this.$store.dispatch('changeLocale', newVal)
        }
      }
    },
    methods: {
      toggleVisibility() {
        this.isVisible = !this.isVisible
      },
      hideDropdown() {
        this.isVisible = false
        this.focusedIndex = 0
      },
      startArrowKeys() {
        if (this.isVisible) {
          // this.$refs.account.focus()
          this.$refs.dropdown.children[0].children[0].focus()
        }
      },
      focusPrevious(isArrowKey) {
        this.focusedIndex = this.focusedIndex - 1
        if (isArrowKey) {
          this.focusItem()
        }
      },
      focusNext(isArrowKey) {
        this.focusedIndex = this.focusedIndex + 1
        if (isArrowKey) {
          this.focusItem()
        }
      },
      focusItem() {
        this.$refs.dropdown.children[this.focusedIndex].children[0].focus()
      },
      setLocale(locale) {
        this.$store.dispatch('changeLocale', locale)
        this.hideDropdown()
      }

    }
}
</script>