<template>
    <nav class="mobile-nav" v-if="lang">
        <div class="container">
            <ul>
                <li class="localswitcher" v-if="displayMobileSwitcher">
                    <LocaleSwitch />
                </li>
                <li class="nav__links" v-for="(link, idx) in links" v-bind:key="idx">
                    <router-link v-on:click.native="closeMenu" :to="{name: link.name, params: { lang: lang}}">
                        {{$t("nav."+`${link.name}`)}}
                    </router-link>
                </li>
                <li class="social__links">
                    <a href="http://www.instagram.com/michaelbutlergallery/">
                        <img src="@/assets/img/icons/instagram-icon.png" alt="Michael Butler Instagram" />
                    </a>
                    <a href="http://www.youtube.com/c/MichaelButlergallery">
                        <img src="@/assets/img/icons/youtube-icon.png" alt="Michael Butler Youtube" />
                    </a>
                </li>
                <li class="secondary__links">
                    <router-link v-for="(secondLink, secondIdx) in secondaryLinks" v-bind:key="secondIdx" 
                    v-on:click.native="closeMenu" :to="{name: secondLink.name, params: { lang: lang}}">
                        {{$t("nav."+`${secondLink.name}`)}}
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>   
</template>

<style lang="scss" scoped>
    // Mobile Nav Menu
    .mobile-nav {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 300px;
    transition: all 0.3s ease;
    background: $black;
    z-index: 9;
    padding: 20vh 0 0 0;
    display: flex;
    flex-flow: column;
    .container {
        flex: 1 1 auto;
    }
    ul {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        li {
            display: flex;
            justify-content: flex-start;
            transition: all 0.3s linear;
            line-height: 1;
            margin: 0 0 25px 0;
            @for $i from 1 to 20 {
                &:nth-child(#{$i}) { transition-delay: $i * 0.12s; }
            }
            opacity: 0;
            a, span {
                color: $white;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            &.nav__links {
                a {
                    font-weight: 500;
                }
            }
            &.social__links {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-self: flex-start;
                a {
                    margin-right: 15px;
                    &:last-of-type{
                        margin-right: 0;;
                    }
                    img {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
            &.secondary__links {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-self: flex-start;
                a {
                    font-weight: 400;
                    font-size: 14px;
                    margin-right: 15px;
                    text-transform: capitalize;
                    &:last-of-type{
                        margin-right: 0;;
                    }
                }
            }
        }
    }
    &.toggle {
        left: 0;
        ul {
            li {
                opacity: 1;
            }
        }
    }

    .nav__quick_contact {
        width: 100%;
        padding: 15px;
        .btn-group {
            .btn {
                flex: 0 0 calc(50% - 7px);
                &.primary {
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
    }

    @media only screen and (max-width: 768px) {
        .mobile-nav {
            padding: 10vh 0 0 0 !important;
        }
    }
</style>

<script>
import { EventBus } from "@/event-bus.js"; 
import LocaleSwitch from '@/components/navigation/LocaleSwitch'
export default {
    name: 'MobileMenu',
    components: {
        LocaleSwitch
    },
    data: function() {
        return {
            links: [
                {
                    id: 0,
                    name: 'home',
                    slug: '/',
                },
                {
                    id: 1,
                    name: 'paintings',
                    slug: '/paintings',
                },   
                {
                    id: 2,
                    name: 'the-artist',
                    slug: '/the-artist',
                },    
                {
                    id: 3,
                    name: 'private-sales',
                    slug: '/private-sales',
                },    
                {
                    id: 4,
                    name: 'press-images',
                    slug: '/press-images',
                },    
                {
                    id: 5,
                    name: 'films',
                    slug: '/films',
                },     
                {
                    id: 6,
                    name: 'contact',
                    slug: '/contact',
                },     
            ],
            secondaryLinks: [
                {
                    id: 0,
                    name: 'credits',
                    slug: '/credits',
                },   
                {
                    id: 1,
                    name: 'privacy-and-cookies',
                    slug: '/privacy-and-cookies',
                },   
            ]
        }
    },
    props: [
       'contact'
    ],
    methods: {
        closeMenu: function (event) {
            if (event) {
                document.querySelector('.mobile-nav').classList.remove('toggle');
                EventBus.$emit("link-clicked", true);
            }
        }
    },
    mounted() {  
        EventBus.$on("menu-toggle", () => {  
            document.querySelector('.mobile-nav').classList.toggle('toggle');
        }); 
    },
    computed: {
        lang: {
            get: function() {
                return this.$store.state.locale
            },
            set: function(newVal) {
                this.$store.dispatch('changeLocale', newVal)
            }
        },
        displayMobileSwitcher() {
            return this.$mq === 'sm' ? true : false
        }
    }
}
</script>