import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import i18n, { selectedLocale } from './i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: selectedLocale,
    isLoading: false,
    pressAuth: false,
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_SESSION(state, pressAuth) {
      state.pressAuth = pressAuth
    }
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale
      commit('updateLocale', newLocale)
    },
  },
  plugins: [createPersistedState()]
})
