import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

// Axios
import axios from 'axios'
Vue.prototype.$http = axios

// AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

// Forms
import VFC from 'vfc'
import 'vfc/dist/vfc.css'
Vue.use(VFC)

// Vue MQ
import VueMq from 'vue-mq'
Vue.use(VueMq, {
  breakpoints: { // default breakpoints
    sm: 768,
    md: 1025,
    lg: 1200,
    xl: Infinity,
  }
})

import Affix from 'vue-affix';
Vue.use(Affix);
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);


// ScrollTo
import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo, {
  container: "body",
  duration: 700,
  easing: "ease",
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

// Global Styles
//import './global-styles/theme.scss'

import i18n from './i18n'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)

// Analytics
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueAnalytics, {
  id: 'UA-124896323-3',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})


import VideoBackground from 'vue-responsive-video-background-player'

Vue.component('video-background', VideoBackground);


// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'en'
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
})

new Vue({
  created() {
    AOS.init()
  },

  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#main-wrapper')
