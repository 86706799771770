<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 signature" data-aos="fade-up" v-bind:data-aos-delay="100" data-aos-once="true" data-aos-anchor="body">
          <img src="@/assets/svg/signature.svg" alt="Michael Butler Signature" />
        </div>
      </div>
      <div class="row">

        <div v-if="currentRouteName === 'privacy-and-cookies'" class="col-md-6 offset-md-6 copyright" data-aos="fade-up" v-bind:data-aos-delay="150" data-aos-once="true" data-aos-anchor="body">
          <p>&copy; {{year}} - {{$t("nav.copyright")}}</p>
        </div>
        
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: "",
      company: 'M.I.A\'S Event Decor'
    }
  },
  computed: {
      currentRouteName() {
          return this.$route.name
      },
  },
  props: [
    'links'
  ],
  methods: {
    getCurrentYear: function () {
      this.year = new Date().getFullYear();
    }
  },
  mounted() {
    this.getCurrentYear();
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding: 30px 0;
  background-color: $black;
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  p {
    font-size: 14px;
  }
  .copyright {
    display: flex;
  }
  .signature {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    img {
      height: 115px;
    }
  }
  .footer__list {
    display: flex;
    align-items: center;
    li {
      p {
        margin: 0;
        color: $white;
        a {
          color: $white;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
  .copyright {
    display: flex;
    justify-content: center;
  }
    .signature img {
    //width: 260px;
    }
    .footer__list {
      justify-content: center;
      flex-flow: column;
      li {
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
   .signature img {
      //width: 400px;
    }
  .copyright {
    display: flex;
    justify-content: flex-end;
  }
    .footer__list {
      justify-content: flex-start;
      flex-flow: row nowrap;
      li {
        margin-right: 30px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

</style>