<template>
  <header class="header dark__nav" on="handleScroll" v-if="lang">
    <div class="header-wrapper" data-aos="fade-down" v-bind:data-aos-delay="100" data-aos-once="true" data-aos-anchor="body">
      <div class="container">

        <!-- Menu Button -->
        <div class="menu-btn" v-on:click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <!-- Logo -->
        <div class="logo">
          <div class="logo__inner">
          <router-link :to="{ name: 'home',
          params: { lang: lang}}">
            <img v-if="$route.params.lang == 'zh-Hans'" src="@/assets/img/logo/michael-butler-logo-simplified.svg" alt="Michael Butler Logo" />
            <img v-else-if="$route.params.lang == 'zh-Hant'" src="@/assets/img/logo/michael-butler-logo-traditional.svg" alt="Michael Butler Logo" />
            <img v-else src="@/assets/img/logo/michael-butler-logo-eng-port.svg" alt="Michael Butler Logo" />
          </router-link>
          </div>
        </div>

        <!-- Locale Switcher -->
        <LocaleSwitch v-if="displayDesktopSwitcher" />
      </div>
    </div>
    <mobile-menu></mobile-menu>
  </header>
</template>

<style scoped lang="scss">
@media only screen and (min-width: 1025px) {
  @keyframes headerExpand {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  @keyframes headerExpand {
    0% {
      transform: translateY(-100px);
    }
    100% {
        transform: translateY(0%);
    }
  }
}

@media only screen and (max-width: 768px) {
  @keyframes headerExpand {
    0% {
      transform: translateY(-100px);
    }
    100% {
        transform: translateY(0%);
    }
  }
}

// Header
.header {
  height: 90px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  will-change: transform, background-color, opacity, padding-top, padding-bottom;
  transition: all 220ms linear !important;
  animation-name: headerExpand;
  animation-duration: 220ms;
  animation-delay: 220ms;
  animation-fill-mode: both;
  opacity: 1 !important;
  transform: translateY(0%);
  @include navTheme();

  .header-wrapper {
    height: 100%;
    align-self: center;
    display: flex;
    position: relative;
    position: relative;
    display: block;
    width: 100%;
    transition: all 0.3s ease;
  }
  // Container
  .container {
    height: 100%;
    /*display: flex;
    flex-flow: row nowrap;*/
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //justify-content: space-between;
    align-items: center;
    position: relative;
  }

  // Logo
  .logo {
    margin: 0 auto;
    .logo__inner {
      a {
        height: 100%;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .logo {
        img {
          //max-width: 215px !important;
        }
    }
  }

  @media only screen and (max-width: 767px) {
    .container {
      grid-template-columns: 64px auto 32px;
      //justify-content: center !important;
    }
    .logo {
        img {
          //object-fit: contain;
          //max-width: 260px;
          //margin-right: -40px;
        }
    }
  }

  @media only screen and (min-width: 768px) {
    .logo {
      position: relative;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      img {
        //object-fit: cover;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .logo {
      img {
        //max-width: 300px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .logo {
      img {
        //max-width: 400px;
      }
    }
  }

  // Nav Menu
  .nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    ul {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      li {
        padding-right: 30px;
        &:last-of-type {
          padding-right: 0;
        }
        &:hover {
          a svg {
            transform: rotate(180deg);
          }
        }
        a {
          text-decoration: none;
          transition: all 0.3s ease;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 2px;
          display: flex;
          flex-flow: row;
          align-items: center;
          svg {
            height: 15px;
            transition: all 0.3s linear;
            margin-left: 1rem;
            margin-bottom: 2px;
          }
        }
        .children {
          transition: all 0.3s linear;
          position: absolute;
          top: 2rem;
          left: 0;
          flex-flow: column;
          padding: 1.5rem;
          width: 100%;
          background: $white;
          visibility: hidden;
          opacity: 0;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          &:before {
            z-index: -1;
            border: 1rem solid transparent;
            border-width: 1.46rem 1rem;
            border-bottom-color: $white;
            top: -2.9rem;
            content: "";
            left: 2.46rem;
            position: absolute;
            transform: translateX(-50%);
          }
          li {
            padding: .5rem 0;
            a {
              color: $primary !important;
            }
          }
        }

        // hover
        &:hover {
          ul {
            opacity: 1;
            visibility: visible;
            top: 3rem;
          }
        }
      }
    }
  }

  // Menu Btn
  .menu-btn {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    left: 0;
    z-index: 10;
    height: 18px;
    width: 40px;
    cursor: pointer;
    span {
      position: absolute;
      left: 0;
      pointer-events: none;
      height: 2px;
      width: 35px;
      transition: all 0.3s cubic-bezier(0.96, -0.07, 0.38, 1.0);
      &:nth-of-type(1) {
        top: 0px;
      }

      &:nth-of-type(2), &:nth-of-type(3) {
        top: 8px;
      }

      &:nth-child(4) {
        top: 16px;
      }
    }
  }
  &.toggle-menu {
    position: fixed;
    .header-wrapper .container {
      &:before {
        opacity: 1;
      }
    }
    .menu-btn span:nth-of-type(1) {
      top: 8px;
      width: 0%;
      left: 50%;
    }

    .menu-btn span:nth-of-type(2) {
      transform: rotate(45deg);
    }

    .menu-btn span:nth-of-type(3) {
      transform: rotate(-45deg);
    }

    .menu-btn span:nth-of-type(4) {
      top: 8px;
      width: 0%;
      left: 50%;
    }
  }


}

@media only screen and (max-width: 1024px) {
  .header {
      &.toggle-menu {
        position: fixed;      
      }
    
    .header-wrapper {
      .container {
        position: relative;
      }
    }

    // Logo 
    .logo {
      svg {
        width: 200px;
      }
    }

  }
}

</style>

<script>
import { EventBus } from "@/event-bus.js";
import MobileMenu from '@/components/navigation/MobileMenu'
import LocaleSwitch from '@/components/navigation/LocaleSwitch'
export default {
  name: 'Header',
  components: {
    LocaleSwitch,
    'mobile-menu' : MobileMenu,
  },
  data: function() {
    return {
      limitPosition: 200,
      scrolled: false,
      lastPosition: 0
    }
  },
  props: [
    'links', 'darkHeader'
  ],
  methods: {
    toggleMenu: function (event) {
      if (event) {
        EventBus.$emit("menu-toggle", true);
        document.querySelector('.header').classList.toggle('toggle-menu');
      }
    },
    handleScroll: function () {
      let header = document.querySelector('.header')
      if(header.classList.contains('toggle-menu')) {
        return false
      } else {
        if (window.scrollY > this.limitPosition) {
          this.scrolled = true;
          header.classList.remove('light__nav');
          header.classList.add('dark__nav');
        } else {
          this.scrolled = false;
        }
      }

      this.lastPosition = window.scrollY;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {  
      EventBus.$on("link-clicked", () => {  
          document.querySelector('.header').classList.remove('toggle-menu');
      });
  },
  computed: {
    lang: {
        get: function() {
            return this.$store.state.locale
        },
        set: function(newVal) {
            this.$store.dispatch('changeLocale', newVal)
        }
    },
    displayDesktopSwitcher() {
        return this.$mq === 'md' || this.$mq === 'lg' || this.$mq === 'xl' ? true : false
    }
  },
}
</script>